import CONSTANT from '../../constants'
import fetchWithAuthorizationHeader from '../../helper/fetchWithAuthorizationHeader'

const { PLATFORMS_API_URL } = CONSTANT

const getSinglePlatform = async (
	platformID: string
): Promise<{
	status: number
	body: Array<object>
}> => {
	const response = await fetchWithAuthorizationHeader(
		`${PLATFORMS_API_URL}?platformID=${platformID}`
	)
	return response.json()
}

export default getSinglePlatform
