import { FormField, Select } from '@cloudscape-design/components'
import { FC, ReactNode } from 'react'
import { Control, Controller, UseControllerProps } from 'react-hook-form'
 
export interface FormSelectProps {
	label: string
	name: string
	control: Control
	options: Array<{ label: string; value: string; description?: string }>
	placeholder: string
	defaultValue?: Array<{ label: string; value: string; description?: string }>
	description?: string
	info?: ReactNode
	selectedOption?: object
	rules?: UseControllerProps['rules']
	disabled?: boolean
	setFormValues: (name: string, value: any) => void
}
 
const FormSelect: FC<FormSelectProps> = ({
	label,
	name,
	control,
	description,
	defaultValue,
	info,
	rules,
	options,
	placeholder,
	disabled = false,
	setFormValues
}) => {
	return (
		<Controller
			name={name}
			control={control}
			rules={rules}
			defaultValue={defaultValue}
			render={({ field: { name, onChange, value }, fieldState: { error } }) => {
				return (
					<FormField
						label={label}
						description={description}
						info={info}
						errorText={error?.message}
					>
						<Select
							selectedOption={value}
							options={options}
							placeholder={placeholder}
							filteringType="auto"
							onChange={({ detail }) => {
								onChange(detail.selectedOption)
								setFormValues(name, detail.selectedOption)
							}}
							disabled={disabled}
						/>
					</FormField>
				)
			}}
		/>
	)
}
 
export default FormSelect
