import {
	Box,
	Button,
	Container,
	Form,
	Header,
	Popover,
	SpaceBetween
} from '@cloudscape-design/components'
import { useOktaAuth } from '@okta/okta-react'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import FormInput from '../../../components/ControlledFormInputs/Input'
import FormRequiredTags from '../../../components/ControlledFormInputs/RequiredTags'

import type { PlatformRequest } from '../../../types/platforms'
import { useNewPlatformFormStore } from '../../../stores/platforms/newPlatformFormStore'
import FormSelect from '../../../components/ControlledFormInputs/Select'
import useCreateNewPlatform from '../../../hooks/mutate/useCreateNewPlatform'
import Icon from '@cloudscape-design/components/icon'
import { ColumnLayout } from '@cloudscape-design/components'
import CONSTANTS from '../../../constants'

const ENVIRONMENT_LIST = [
	{ label: 'dev', value: 'dev' },
	{ label: 'staging', value: 'staging' },
	{ label: 'prod', value: 'prod' }
]

const SIZE_LIST = [
	{ label: 'small', value: 'small' },
	{ label: 'medium', value: 'medium' },
	{ label: 'large', value: 'large' }
]

const TYPE_LIST = [
	{ label: 'standard', value: 'standard' },
	{ label: 'autopilot', value: 'autopilot' }
]

const NewPlatformForm: FC = () => {
	const { authState } = useOktaAuth()
	const thisUserEmail = authState?.idToken?.claims.email as string

	const navigate = useNavigate()
	const {
		formValues,
		actions: { setFormValues, clearFormValues }
	} = useNewPlatformFormStore()

	const { mutate: createNewPlatform, isLoading } = useCreateNewPlatform()
	const {
		control,
		setValue,
		trigger,
		formState: { isValid }
	} = useForm({
		mode: 'onChange',
		reValidateMode: 'onChange',
		defaultValues: formValues
	})
	const [emails, setEmails] = useState([])

	const [popFields, setPopFields] = useState(false)

	const handleSubmit = async () => {
		await trigger()
		if (!isValid) return
		const platform: PlatformRequest = {
			requestorID: thisUserEmail,
			action: 'CREATE',
			details: formValues,
			status: 'PENDING_APPROVAL',
			creationTime: new Date().toISOString()
		}
		createNewPlatform(platform, {
			onSuccess: () => {
				navigate('/platforms')
				clearFormValues()
				window.location.reload()
			}
		})
	}
	useEffect(() => {
		setValue('region', 'Singapore (asia-southeast1)')
	}, [])

	useEffect(() => {
		if (formValues?.platformType?.value === 'standard') {
		  setPopFields(true)
		} else {
		  setPopFields(false)
		}
	}, [formValues?.platformType?.value, setPopFields, formValues])

	return (
		<Form
			actions={
				<SpaceBetween direction="horizontal" size="xs">
					<Box>
						<Button
							variant="primary"
							onClick={handleSubmit}
							loading={isLoading}
						>
							Submit
						</Button>
						<Button variant="link" onClick={() => navigate(-1)}>
							Cancel
						</Button>
					</Box>
				</SpaceBetween>
			}
			header={
				<Header variant="h1" description="">
					Request a New Platform
				</Header>
			}
		>
			<SpaceBetween direction="vertical" size="s">
				<Container>
					<SpaceBetween direction="vertical" size="l">
						<div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
							<div style={{ width: '650px' }}>
								<FormInput
									name="platformName"
									label="Platform Name"
									type="text"
									control={control}
									placeholder="Enter Platform Name"
									setFormValues={setFormValues}
									description="Max 20 Characters"
									rules={{
										required: 'Please enter platform name',
										maxLength: {
											value: 20,
											message: 'Maximum length is 20 characters'
										},
										pattern: {
											value: /^[a-z0-9-]+$/,
											message:
												'Platform name should be lowercase only and whitespaces are not allowed.'
										}
									}}
								/>
							</div>
						</div>
						<div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap'  }}>
							<div style={{ width: '650px' }}>
								<FormSelect
									label="Environment"
									name="environment"
									control={control}
									rules={{
										required: 'Please Select Option'
									}}
									description="GKE Cluster Environment (Dev, Staging, Prod)
							Recommendation: Always start with DEV env first, followed by STG, then PRD"
									setFormValues={setFormValues}
									options={ENVIRONMENT_LIST}
									placeholder="Select Environment"
								/>
							</div>
						</div>
						<div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap'  }}>
							<div style={{ width: '650px' }}>
								<FormInput
									name="region"
									label="Region"
									type="text"
									control={control}
									setFormValues={setFormValues}
									description="Region of your GKE Cluster (by default Singapore)"
									disabled={true}
								/>
							</div>
						</div> 
						<div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
							<div style={{ width: '650px' }}>
								<FormInput
									name="platformViewer"
									label="Platform Viewer(s)"
									type="text"
									control={control}
									description="Email addresses for viewer-level permissions on the project (separated by commas)"
									placeholder="Enter Email Addresses"
									setFormValues={setFormValues}
									rules={{
										required: 'Please enter email address',
										pattern: {
											value:
												/^[a-z0-9._%+-]+@globe\.com\.ph(?:,[a-z0-9._%+-]+@globe\.com\.ph)*$/,
											message:
												'Please enter valid email addresses separated by commas, without any whitespaces, and ending with @globe.com.ph.'
										}
									}}
								/>
							</div>
						</div>
						<div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
							<div style={{ width: '650px' }}>
								<FormInput
									name="gcpProjectID"
									label="GCP Project ID"
									type="text"
									control={control}
									placeholder="Enter GCP Project ID"
									setFormValues={setFormValues}
									rules={{
										required: 'Please enter the GCP Project ID'
									}}
								/>
							</div>
							<div style={{ marginLeft: '10px', marginTop: '25px' }}>
								<Popover
									fixedWidth
									header="Platform GCP Project ID tip"
									position="right"
									triggerType="custom"
									content={
										<p>
											Please see guide on how to enter your GCP Project ID:
											<a
												href={`${CONSTANTS.COMPASS_BASEURL}/gcp/user-guide/asp/developer-enablement/platform-onboarding/identifying_gcp_project/`}
												target="_blank"
												rel="noopener noreferrer"
												aria-label="Plus"
												style={{
													marginLeft: '10px',
													marginTop: '25px'
												}}
											>
												LINK
											</a>
										</p>
									}
								>
									<Icon name="status-info" />
								</Popover>
							</div>
						</div>

						<div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
							<div style={{ width: '650px' }}>
								<FormSelect
									name="platformType"
									label="Platform Type"
									control={control}
									rules={{
										required: 'Please Select Option'
									}}
									setFormValues={setFormValues}
									options={TYPE_LIST}
									placeholder="Select GKE Cluster Type"
									description="Select cluster type based on the tool tip (Standard, Autopilot)"
								/>
							</div>
							<div style={{ marginLeft: '10px', marginTop: '38px' }}>
								<Popover
									fixedWidth
									header="Platform Type tool tip"
									position="right"
									triggerType="custom"
									content={
										<p>
											Please see guide on how to select your platform type:
											<a
												href={`${CONSTANTS.COMPASS_BASEURL}/gcp/user-guide/asp/developer-enablement/platform-onboarding/platform_tshirt_type_size/`}
												target="_blank"
												rel="noopener noreferrer"
												aria-label="Plus"
												style={{
													marginLeft: '10px',
													marginTop: '25px'
												}}
											>
												LINK
											</a>
										</p>
									}
								>
								</Popover>
							</div>
						</div>
						{popFields && (
							<>
								<div style={{ maxWidth: 660 }}>
									<FormInput
										label="Minimum Nodes"
										name="minNode"
										control={control}
										type="number"
										setFormValues={setFormValues}
										disabled={false}
										placeholder="Add Minimum Nodes"
										rules={{
											required: 'Please enter a value',
											min: {
												value: 1,
												message: 'Minimum value is 1'
											}
										}}
									/>
								</div>
								<div style={{ maxWidth: 660 }}>
									<FormInput
										name="maxNode"
										label="Maximum Nodes"
										placeholder="Add Maximum Nodes"
										type="number"
										control={control}
										setFormValues={setFormValues}
										disabled={false}
										rules={{
											required: 'Please enter a value',
											min: {
												value: 1,
												message: 'Minimum value is 1'
											}
										}}
									/>
								</div>
								<div style={{ maxWidth: 660 }}>
								<FormInput
										name="machineType"
										label="Machine Type"
										placeholder="Machine Type"
										type="text"
										control={control}
										setFormValues={setFormValues}
										disabled={false}
										rules={{
											required: 'Please enter a value',
											pattern: {
												value: /^[a-zA-Z][a-zA-Z0-9-]*$/,
												message: 'Machine Type must be alphanumeric'
											}
										}}
									/>
								</div>
							</>
						)}

						<div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'  }}>
							<div style={{ width: '650px' }}>
								<FormSelect
									name="platformSize"
									label="Platform Size"
									control={control}
									rules={{
										required: 'Please Select Option'
									}}
									setFormValues={setFormValues}
									options={SIZE_LIST}
									placeholder="Select Cluster Size"
									description="Select cluster size based on the tool tip (Small, Medium, Large)"
								/>
							</div>
							<div style={{ marginLeft: '10px', marginTop: '38px' }}>
								<Popover
									fixedWidth
									header="Platform Cluster Size tool tip"
									position="right"
									triggerType="custom"
									content={
										<p>
											Please see guide on how to select the size for your GKE
											cluster:
											<a
												href={`${CONSTANTS.COMPASS_BASEURL}/gcp/user-guide/asp/developer-enablement/platform-onboarding/platform_tshirt_type_size/`}
												target="_blank"
												rel="noopener noreferrer"
												aria-label="Plus"
												style={{
													marginLeft: '10px',
													marginTop: '25px'
												}}
											>
												LINK
											</a>
										</p>
									}
								>
									<Icon name="status-info" />
								</Popover>
							</div>
						</div>
						<div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap'  }}>
							<div style={{ width: '650px' }}>
								<FormInput
									name="systemAlias"
									label="System Alias"
									type="text"
									control={control}
									placeholder="Enter System Alias"
									setFormValues={setFormValues}
									description="Max 5 Characters"
									rules={{
										required: 'Please enter system alias',
										maxLength: {
											value: 5,
											message: 'Maximum length is 5 characters'
										},
										pattern: {
											value: /^[a-z0-9-]+$/,
											message: 'System alias should be lowercase only'
										}
									}}
								/>
							</div>
						</div>
					</SpaceBetween>
					<SpaceBetween direction="vertical" size="l">
						<div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap'  }}>
							<div style={{ marginTop:'10px', marginBottom:'-20px' }}>
								<p style={{ fontSize: '15px', fontWeight: 'bold'}}>
									Platform Details
								</p>
								<p style={{ color: 'grey', fontSize: 'small', marginTop: '-10px',textAlign: 'left' }}>
									For the Platform Owner enter the name of the person who will own the platform (not email, just the name)
								</p>
							</div>
							<div style={{ marginLeft: '10px', marginTop: '15px' }}>
								<Popover
									fixedWidth
									header="Platform Details tool tip"
									position="right"
									triggerType="custom"
									content={
										<p>
											Please see guide on how to fill the platform details:
											<a
												href={`${CONSTANTS.COMPASS_BASEURL}/gcp/user-guide/asp/developer-enablement/platform-onboarding/platform_onboarding/`}
												target="_blank"
												rel="noopener noreferrer"
												aria-label="Plus"
												style={{
													marginLeft: '10px',
													marginTop: '25px'
												}}
											>
												LINK
											</a>
										</p>
									}
								>
									<Icon name="status-info" />
								</Popover>
							</div>
						</div>

						<div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
							<div style={{ width: '650px' }}>
								<ColumnLayout columns={2} variant="default">
									<FormInput
										name="platformOwner"
										label="Platform Owner"
										type="text"
										control={control}
										placeholder="Enter Platform Owner"
										setFormValues={setFormValues}
										rules={{
											required: 'Please enter platform owner',
											pattern: {
												value: /^[a-z-]+$/,
												message:
													'Platform Owner should be lowercase only and no mumbers allowed and whitespaces are not allowed.'
											}
										}}
									/>
									<FormInput
										name="wbsCode"
										label="WBS Code"
										type="text"
										control={control}
										placeholder="Enter WBS Code"
										setFormValues={setFormValues}
										rules={{
											required: 'Please enter wbs code',
											pattern: {
												value: /^[a-z0-9-]+$/,
												message:
													'WBS code should be lowercase only and whitespaces are not allowed.'
											}
										}}
									/>
									<FormInput
										name="group"
										label="Group"
										type="text"
										control={control}
										placeholder="Enter Group"
										setFormValues={setFormValues}
										rules={{
											required: 'Please enter group',
											maxLength: {
												value: 3,
												message: 'Maximum length is 3 characters'
											},
											pattern: {
												value: /^[a-z]+$/,
												message:
													'Group should be lowercase only and whitespaces are not allowed.'
											}
										}}
									/>
									<FormInput
										name="costCenter"
										label="Cost Center"
										type="text"
										control={control}
										placeholder="Enter Cost Center"
										setFormValues={setFormValues}
										rules={{
											required: 'Please enter cost center',
											pattern: {
												value: /^[a-z0-9-]+$/,
												message:
													'Cost center should be lowercase only and whitespaces are not allowed.'
											}
										}}
									/>
								</ColumnLayout>
							</div>
						</div>
					</SpaceBetween>
				</Container>
			</SpaceBetween>
		</Form>
	)
}

export default NewPlatformForm