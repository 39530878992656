import { FC, useEffect } from 'react'
import { ColumnLayout } from '@cloudscape-design/components'
import FormInput from '../Input'
import { useForm } from 'react-hook-form'
import FormSelect from '../Select'
import { useGenericTagsManagementFormStore } from '../../../stores/tags/genericTagsManagementFormStore'
import CONSTANT from '../../../constants'
 
const { DATA_CLASSIFICATION_LIST, PLATFORM_REPO_LIST, COST_TYPE_LIST, PLATFORM_LIST } = CONSTANT
 
const GenericTagsNew: FC = (defaultValues) => {
    const {
        formValues,
        actions: { setFormValues, setFormValidity }
    } = useGenericTagsManagementFormStore()
 
    const {
        control,
        formState: { isValid },
        setValue
    } = useForm({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: formValues,
        shouldUseNativeValidation: true
    })
 
    useEffect(() => {
        setFormValidity(isValid)
    }, [isValid, setFormValidity])
 
    useEffect(() => {
        if (formValues?.fundingSourceType && formValues.fundingSourceType?.value === 'opex') {
            setValue('WBSCode', '')
			setFormValues('WBSCode', '')
        } else if (formValues?.fundingSourceType && formValues.fundingSourceType?.value === 'capex') {
            setValue('costCenter', '')
			setFormValues('costCenter', '')
        }
    }, [formValues.fundingSourceType, setValue, setFormValues])
 
    return (
        <ColumnLayout columns={2} variant="default">
            <FormSelect
                name="platformName"
                label="Platform Name"
                control={control}
				rules={{
					required: 'Please Select Option'
				}}
				setFormValues={setFormValues}
				options={PLATFORM_LIST}
				placeholder="Select Platform "
			/>
			<FormInput
				name="tagProject"
				label="Project"
				type="text"
				control={control}
				rules={{
					required: `Please enter valid value, Example: LUNA, JANUS, VIASH, UDG1:PH2`,
					pattern: {
						//value: /^[A-Z0-9]+(?::(PH|FS|UC)[A-Z0-9]{2,})?(?:-(TR|CR|DR)[A-Z0-9]{2,})?$/g,
						value: /^[A-Z0-9]+(?::[A-Z0-9]+)?(?:-[A-Z0-9]+)*$/g,
						message:
							'Please enter valid value, Example: LUNA, JANUS, VIASH, UDG1:PH2'
					},
					minLength: {
						value: 3,
						message: 'Minimum length should be 3'
					}
				}}
				setFormValues={setFormValues}
				placeholder="Example: LUNA, JANUS, VIASH, UDG1:PH2"
			/>
			{/* <FormSelect
				label="Platform Repo Type"
				name="platformRepo"
				control={control}
				rules={{
					required: 'Please Select Option'
				}}
				setFormValues={setFormValues}
				options={PLATFORM_REPO_LIST}
				placeholder="Select Platform Repo Type"
			/> */}
			<FormInput
				name="systemAlias"
				label="System Alias"
				type="text"
				placeholder="Example: hipms, namax"
				control={control}
				rules={{
					required: 'System alias should be 5 letters, Example: hipms',
					pattern: {
						value: /^[A-Za-z][A-Za-z0-9-]{4,}$/g,
						message: 'System alias should be 5 letters, Example: hipms'
					},
					minLength: {
						value: 5,
						message: 'System alias should be 5 letters, Example: hipms'
					},
					maxLength: {
						value: 5,
						message: 'System alias should be 5 letters, Example: namax'
					}
				}}
				setFormValues={setFormValues}
			/>
			<FormSelect
				label="Data Classification"
				name="dataClassification"
				control={control}
				rules={{
					required: 'Please Select Option'
				}}
				setFormValues={setFormValues}
				options={DATA_CLASSIFICATION_LIST}
				placeholder="Select Data Classification"
			/>
			<FormSelect
				label="Funding Source"
				name="fundingSourceType"
				control={control}
				rules={{
					required: 'Please Select Option'
				}}
				setFormValues={setFormValues}
				options={COST_TYPE_LIST}
				placeholder="Select Funding Source Type"
			/>
			<br />
			<FormInput
				name="WBSCode"
				label="WBS Code"
				type="text"
				control={control}
				rules={{
					pattern: {
						value: /^[A-Z0-9]{2,5}-[A-Z0-9]{1,15}-[0-9]{2}(?:-[A-Z0-9]{2,})?(?:-[A-Z0-9]{2,})?$/g,
						message: 'Please Enter all in CAPS, Ex. ISG-ANODOT-23, EDO-ARROW-23, ISG-PROJECTA-23, EDO1-PROJECTA-24, EDO-PROJECTC-24-SW-01'
					},
					minLength: {
						value: 3,
						message: 'Minimum length should be 3'
					}
				}}
				setFormValues={setFormValues}
				placeholder="Example: ISG-ANODOT-23, EDO-ARROW-23, ISG-PROJECTA-23, EDO1-PROJECTA-24, EDO-PROJECTC-24-SW-01"
				disabled={
					formValues?.fundingSourceType &&
					formValues.fundingSourceType.value === 'opex'
				}
			/>
			<FormInput
				name="costCenter"
				label="Cost Center"
				type="text"
				control={control}
				rules={{
					pattern: {
						value: /^[A-Z0-9]{1,5}-[A-Z0-9]{1,3}$/g,
						message: 'Please Enter all in CAPS, Ex. ISG12-M2, EDOM1-24, EGA-A12, ISGM1-H1'
					},
					minLength: {
						value: 3,
						message: 'Minimum length should be 3'
					}
				}}
				setFormValues={setFormValues}
				placeholder="Example: ISG12-M2, EDOM1-24, EGA-A12, ISGM1-H1"
				disabled={
					formValues?.fundingSourceType &&
					formValues.fundingSourceType.value === 'capex'
				}
			/>
		</ColumnLayout>
	)
}
 
export default GenericTagsNew