import { produce } from 'immer'
import { platform } from 'os'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import { create } from 'zustand'

type NewPlatformFormState = {
	isFormValid: boolean
	formValues: Record<string, any>
}

type NewPlatformFormActions = {
	actions: {
		clearFormValues: () => void
		setFormValues: (fieldName: string, value: any) => void
		setFormValidity: (isValid: boolean) => void
	}
}

const initialFormValues = {
	environment: '',
	platformViewer: [],
	region:'asia-southeast1',
	platformName: '',
	platformOwner: '',
	systemAlias:'',
	wbsCode: '',
	costCenter: '',
	group: '',
	creationTime: '',
	minNode: '',
	maxNode: '',
	machineType: ''
}

const newPlatformFromStore = create<
	NewPlatformFormState & NewPlatformFormActions
>((set, _get) => ({
	isFormValid: false,
	formValues: initialFormValues,
	actions: {
		clearFormValues: () => set(() => ({ formValues: initialFormValues })),

		setFormValidity: (isValid) => set(() => ({ isFormValid: isValid })),

		setFormValues: (fieldName, value) =>
			set((state) =>
				produce(state, (draft) => {
					if (fieldName === 'platformViewer') {
						const emails = value.split(',').map((email: string) => email.trim())
						draft.formValues[fieldName] = emails
					} else {
						draft.formValues[fieldName] = value
					}
				})
			)
	}
}))

export const useNewPlatformFormStore = () =>
	newPlatformFromStore((state) => state)

if (process.env.NODE_ENV !== 'production') {
	mountStoreDevtool('NewPlatformFormStore', newPlatformFromStore)
}
