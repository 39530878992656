import CONSTANT from '../../constants'
import fetchWithAuthorizationHeader from '../../helper/fetchWithAuthorizationHeader'

const { APPLICATIONS_API_URL } = CONSTANT

const getSingleApplication = async (
	applicationID: string
): Promise<{
	status: number
	body: Array<object>
}> => {
	const response = await fetchWithAuthorizationHeader(
		`${APPLICATIONS_API_URL}?applicationID=${applicationID}`
	)
	return response.json()
}

export default getSingleApplication
