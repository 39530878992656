import { useQuery } from '@tanstack/react-query'

import type { gcpProject } from '../../types/gcpProject'
import api from '../../api'

const useGcpProjects = () => {
	return useQuery({
		queryKey: ['gcpProjects'],
		queryFn: () => api.gcpProjects.getgcpProjects(),
		select(data) {
			return data.body as Array<gcpProject>
		}
	})
}

export default useGcpProjects